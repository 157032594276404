import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/eleanor-auto/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const EleanorAuto = ({ data, path }) => {

    return (
        <Project
            title="Eleanor Auto"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                    Eleanor Auto is a one-page responsive website that showcases a 
                    clean layout coupled with bold yet elegant animations to tell 
                    the story from picking your car to driving a new car every month. 
                    It was designed on Figma by Reform Collective, and I developed it 
                    using React, styled-components, and GSAP.
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('eAuto')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('eAuto')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('eAuto')} />
                </div>
                <FeatureCreator projectData={projects.get('eAuto')} />
             </Content>
        </Project>
    )
}


EleanorAuto.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default EleanorAuto